//JQuery
import $ from "jquery"
//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

//Bootbox
//import bootbox from 'bootbox';

//Autocomplete
import 'devbridge-autocomplete'
//OpenLayers
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import TileWMS from 'ol/source/TileWMS.js';
import Projection from 'ol/proj/Projection.js';

//Main CSS
import '../css/main.css'

//Get layer
function getLayerName(){
    return window.location.hash === "#nparking" ? 'campulung_site_parcari' : 'campulung_site'
}
//Const
const wmsLayer = 'campulung';
const wmsURL = 'https://s1.nanoterra.ro/?';
const baseWMSLayer = getLayerName();
const bounds = [498825, 410977, 509417, 423876];
//stereo 70
const epsg31700 = new Projection({
    code: 'EPSG:31700',
    units: 'm',
    axisOrientation : 'neu'
});
$(function() {
    //storage name
    let storageName = 'cl_harta_website';
    //default vars
    let unloaded = false, center = null, zoom = null;
    //get storage
    if(localStorage.getItem(storageName)){
        let sVal = localStorage.getItem(storageName).split('|');
        if(sVal.length === 3 && sVal[0].length < 20) {
            center = [parseFloat(sVal[0]), parseFloat(sVal[1])];
            zoom = sVal[2];
        }
    }
    //default center
    if(center === null) {
        center = [503721.008970, 419346.754258];
        zoom = 19;
    }
    let mapSource = new TileWMS({
        ratio: 1,
        url: wmsURL,
        params: {
            'LAYERS': baseWMSLayer,
            'FORMAT': 'image/jpeg'
        },
        gutter: 0,
        serverType: 'geoserver'
    });
    let mapView = new View({
        projection: epsg31700,
        minZoom: 14,
        maxZoom: 20
    });
    let map = new Map({
        target: 'map',
        layers: [
            new TileLayer({
                extent: bounds,
                source: mapSource,
                title: wmsLayer,
                zoomFactor: 1
            })
        ],
        view: mapView
    });

    mapView.setCenter(center);
    mapView.setZoom(zoom);

    //address search
    let searchAfter = "#search_after";
    let searchInput = "[name='search']";
    let f = $(searchAfter).val();
    let load_autocomplete = function(c) {
        if (c === "strazi") $(searchInput).attr('placeholder', 'Cautare dupa numele strazii...');
        else if (c === "cladiri") $(searchInput).attr('placeholder', 'Cauta o adresa...');
        $(searchInput).autocomplete('clear').autocomplete({
            serviceUrl: 'https://app.nanoterra.ro/harti/ajax/search?oras=campulung&search_after=' + c,
            noCache: true,
            minChars: 2,
            deferRequestBy: 300,
            dataType: 'jsonp',
            crossDomain: true,
            onSelect: function (a) {
                //view
                let view = map.getView();
                //center
                view.setCenter([parseFloat(a.x), parseFloat(a.y)]);
                //zoom
                view.setZoom(20);
                //claer input
                $('[name="search"]').val('');
            },
            onSearchStart: function () {
                $("#search_loader").show()
            },
            onSearchComplete: function (a, b) {
                $("#search_loader").hide()
            },
            transformResult: function (b) {
                return {
                    suggestions: $.map(b.suggestions, function (a) {
                        return {
                            value: a.name,
                            data: a.name,
                            x: a.x,
                            y: a.y
                        }
                    })
                }
            }
        });
    };
    $(searchAfter).change(function () {
        $(searchInput).val("");
        load_autocomplete($(this).val())
    });
    load_autocomplete(f);

    //hash change
    $(window).on('hashchange', function() {
        mapSource.params_.LAYERS = getLayerName();
        mapSource.changed();
        mapSource.refresh();
        map.renderSync();
        map.updateSize();
    });

    //remember map center & zoom
    $(window).bind('beforeunload', function( event ) {
        if(!unloaded) {
            localStorage.setItem(storageName, mapView.getCenter().join('|') + '|' + parseFloat(mapView.getZoom()));
            unloaded = true;
        }
    });
});